import React, {Suspense, useEffect, useState} from 'react';
import {
    IonApp, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle,
    IonIcon,
    IonLabel,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs,
    setupIonicReact
} from '@ionic/react';
import {IonSpinner} from '@ionic/react';
import {IonReactRouter} from "@ionic/react-router";
import {logOutOutline, mapOutline, search} from "ionicons/icons";
import Tab1 from "./tabs/Tab1";
import Tab2 from "./tabs/Tab2";
import {Route} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import {isMobile} from "react-device-detect";
import {Auth} from '@supabase/auth-ui-react';
import {ThemeSupa} from '@supabase/auth-ui-shared';
import {supabase} from './supabaseClient';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';


/* Theme variables */
import './theme/variables.css';

setupIonicReact();



const App = () => {
    const {t, ready} = useTranslation();
    const [session, setSession] = useState(null);

    useEffect(() => {
        supabase.auth.getSession().then(({data: {session}}) => {
            setSession(session);
        });

        const {data: {subscription}} = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session);
        });

        return () => subscription.unsubscribe();
    }, []);

    const handleLogout = async () => {
        await supabase.auth.signOut();
        setSession(null); // Reinicia la sesión a null para volver a la pantalla de login
    };

    if (isMobile) {
        return null;
    }

    if (!session) {
        return (
            <div
                style={{
                    width: "100vw",
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#f4f4f4"
                }}
            >
                <IonCard>
                    <IonCardHeader style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}><h3>Bienvenido a BuscaPK</h3>
                    </IonCardHeader>

                    <IonCardContent>
                        <IonCardContent style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <Auth supabaseClient={supabase}
                                  appearance={{
                                      theme: ThemeSupa,
                                      variables: {
                                          default: {
                                              colors: {
                                                  brand: '#0e0a8f',
                                                  brandAccent: '#3e3ca8'
                                              },
                                          }
                                      }
                                  }}
                                  providers={[]}
                                  showLinks={false}
                                  localization={{
                                      variables: {
                                          sign_in: {
                                              email_label: 'Correo electrónico',
                                              password_label: 'Contraseña',
                                              button_label: 'Iniciar sesión',
                                              email_input_placeholder: 'Correo electrónico',
                                              password_input_placeholder: 'Contraseña',
                                          }
                                      }
                                  }}

                            />
                        </IonCardContent>
                    </IonCardContent>
                    <IonCardSubtitle style={{padding: '20px'}}>
                        <p>Si encuentra algún problema por favor escriba a <a
                            href="mailto:info@buscapk.es">info@buscapk.es</a></p></IonCardSubtitle>
                </IonCard>
            </div>);
    }

    return (
        <IonApp>
            <IonReactRouter>
                <IonTabs>
                    <IonRouterOutlet>
                        <Route path="/" component={Tab1} exact={true}/>
                        <Route path="/search" component={Tab2} exact={true}/>
                    </IonRouterOutlet>

                    <IonTabBar slot="bottom" style={{paddingBottom: '20px'}}>
                        <IonTabButton tab="map" href="/">
                            <IonIcon icon={mapOutline}/>
                            <IonLabel>{t('tabs.map')}</IonLabel>
                        </IonTabButton>

                        <IonTabButton tab="search" href="/search">
                            <IonIcon icon={search}/>
                            <IonLabel>{t('tabs.search')}</IonLabel>
                        </IonTabButton>

                        {/* Botón de logout */}
                        <IonTabButton tab="logout" onClick={handleLogout}>
                            <IonIcon icon={logOutOutline}/>
                            <IonLabel>{t('tabs.logout')}</IonLabel>
                        </IonTabButton>
                    </IonTabBar>
                </IonTabs>
            </IonReactRouter>
        </IonApp>
    );
};

export default function WrappedApp() {
    return (
        <Suspense fallback={
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
                <IonSpinner/>
            </div>
        }>
            <App/>
        </Suspense>
    )
        ;
}
